import { ProductName } from '@pypestream/api-services';

import { GetUserProjectsLimitedQuery } from '@pypestream/api-services/urql';
import { CommonProduct, Project } from '../universal-nav/types';
import { generateBaseURLsToTools } from './generate-base-urls/generate-base-urls';

export interface GetProductsProps {
  project: Project;
  assignedRoles: {
    productId: string;
    product: {
      name: string;
    };
  }[];
  allProducts: GetUserProjectsLimitedQuery['tenancy_products'];
}

interface GetProducts {
  (props: GetProductsProps): CommonProduct[];
}

export const getProducts: GetProducts = ({
  project,
  allProducts,
  assignedRoles,
}) => {
  const { id: projectId } = project;

  // @todo remove contactCenterTempId after fixes from the CC side
  const contactCenterTempId =
    project.projectProductSettings.find(
      (projectProductSetting) =>
        !!projectProductSetting.settings?.agentAssist?.accountId
    )?.settings?.agentAssist?.accountId || projectId;

  const productIds = allProducts.reduce(
    (acc, { name, id }) => ({ ...acc, [`${name}`]: id }),
    {} as Record<ProductName, string>
  );

  const projectProducts =
    project?.projectProductSettings?.map(({ productId }) => productId) || [];

  const hasAgentAssistRoles = !!assignedRoles.find(
    (assignedRole) => assignedRole.product.name === ProductName.AgentAssist
  );

  const hasAnalyticsRoles = !!assignedRoles.find(
    (assignedRole) => assignedRole.product.name === ProductName.Analytics
  );

  const productBaseURLs = generateBaseURLsToTools(window.location);
  const managerURL = window.location.host.includes('manager')
    ? `/organization/${project.accountId}/projects/${projectId}`
    : `${productBaseURLs.Manager}/organization/${project.accountId}/projects/${projectId}`;

  const studioURL = `${productBaseURLs.Studio}/${projectId}`;
  // @todo remove contactCenterTempId after fixes from the CC side
  const contactCenterUrl =
    hasAgentAssistRoles && contactCenterTempId
      ? `${productBaseURLs.ContactCenter}/app/accounts/${contactCenterTempId}/dashboard`
      : undefined;
  const analyticsURL = `${productBaseURLs.Analytics}/api/projects/${projectId}/change`;

  const commonProductsProps: CommonProduct[] = [
    {
      name: ProductName.Organization,
      label: 'Manager',
      logo: 'org',
      url: managerURL,
      disabled: false,
      // It means Manager is always available.
      integrated: true,
      index: 1,
    },
    {
      name: ProductName.ProStudio,
      label: 'Studio',
      logo: 'studio',
      url: studioURL,
      conditional: true,
      // @todo: define disabled condition for Studio
      disabled: true,
      index: 4,
    },
    {
      name: ProductName.AgentAssist,
      label: 'Contact Center',
      logo: 'agentAssist',
      url: contactCenterUrl,
      conditional: true,
      disabled: !hasAgentAssistRoles,
      index: 2,
    },
    {
      name: ProductName.Analytics,
      label: 'Analytics',
      logo: 'analytics',
      // @todo: generate correct link for Analytics when service will be ready
      url: analyticsURL,
      conditional: true,
      disabled: !hasAnalyticsRoles,
      index: 3,
    },
  ];
  const products: CommonProduct[] = commonProductsProps
    .map((cProduct) => {
      const productId = cProduct.name ? productIds?.[cProduct.name] || '' : '';
      const integrated = projectId
        ? projectProducts?.includes(productId)
        : false;
      const isDisabled = cProduct.conditional
        ? cProduct.disabled || !integrated
        : cProduct.disabled;

      return {
        ...cProduct,
        id: productId,
        disabled: isDisabled && cProduct.name !== ProductName.Organization,
        integrated: cProduct.integrated || integrated,
      };
    })
    .filter((cProduct) => cProduct.integrated);

  return products;
};
