/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import { ProductName } from '@pypestream/api-services';
import {
  GetUserInfoLimitedQuery,
  GetUserProjectsLimitedQuery,
  GetUserSettingsLimitedQuery,
  ProjectsSubscriptionLimitedSubscription,
  UpdateUserLimitedMutationVariables,
} from '@pypestream/api-services/urql';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';
import { Typestate } from 'xstate';

import { orderBy } from 'lodash';
import {
  AvailableFeatureFlags,
  FeatureFlagType,
} from '../../feature-flags/feature-flags.types';
import { CommonProject } from '../universal-nav/types';
import { getProducts, GetProductsProps } from '../utils/get-common-projects';

export interface Language {
  id: string;
  languageCode: string;
  locale: string;
  name: string;
}

export interface Timezone {
  id: string;
  identifier: string;
  label: string;
}

type Subpath<T, Key extends keyof T = keyof T> =
  T[Key] extends Record<'states', any>
    ? `${Key & string}.${MachineStateSchemaPaths<
        T[Key]['states'], // need to skip `states` object
        Exclude<keyof T[Key]['states'], keyof any[]>
      >}`
    : T[Key] extends Record<string, any>
      ? `${Key & string}.${MachineStateSchemaPaths<
          T[Key],
          Exclude<keyof T[Key], keyof any[]>
        >}`
      : never;

export type MachineStateSchemaPaths<
  MachineStateSchema extends Record<string, any>,
  Key extends keyof MachineStateSchema = keyof MachineStateSchema,
> = Key extends string ? Key | Subpath<MachineStateSchema, Key> : never;

export type User = NonNullable<
  GetUserInfoLimitedQuery['admin_']
>['currentUser'];

export type UserSettings = GetUserSettingsLimitedQuery['admin_'];

export interface SmartContext {
  app?: ProductName;
  accountId?: string;
  userInfo?: User;
  userSettings: UserSettings;
  allProducts?: GetUserProjectsLimitedQuery['tenancy_products'];
  assignedProjects?: CommonProject[];
  languages: Language[];
  timezones: Timezone[];
  featureFlags?: FeatureFlagType;
  onManagerToolClick?: (route: string) => void;
  hasAvailableTools?: boolean;
  projectsLimitedUnsubscribe?: () => void;
}

export interface SmartState {
  states: {
    userInfo: {
      states: {
        idle: Record<string, unknown>;
        loading: Record<string, unknown>;
        loaded: Record<string, unknown>;
        loadError: Record<string, unknown>;
        updating: Record<string, unknown>;
      };
    };
    projects: {
      states: {
        idle: Record<string, unknown>;
        loading: Record<string, unknown>;
        setupSubscription: {
          states: {
            settingUp: Record<string, unknown>;
            completed: Record<string, unknown>;
            error: Record<string, unknown>;
          };
        };
        loadError: Record<string, unknown>;
      };
    };
    languages: {
      states: {
        loading: Record<string, unknown>;
        loaded: Record<string, unknown>;
        loadError: Record<string, unknown>;
      };
    };
    timezones: {
      states: {
        loading: Record<string, unknown>;
        loaded: Record<string, unknown>;
        loadError: Record<string, unknown>;
      };
    };
    featureFlags: {
      states: {
        idle: Record<string, unknown>;
        loaded: {
          states: {
            withActualValue: Record<string, unknown>;
            // With error state, we will initialized all featureFlags with default compile time values.
            withDefaultValues: Record<string, unknown>;
          };
        };
      };
    };
    logout: {
      states: {
        idle: Record<string, unknown>;
        loading: Record<string, unknown>;
        loaded: Record<string, unknown>;
        error: Record<string, unknown>;
      };
    };
  };
}

export type SmartStateValues = MachineStateSchemaPaths<SmartState['states']>;

export interface SmartTypestates extends Typestate<SmartContext> {
  context: SmartContext;
  value: SmartStateValues;
}

export type SmartEvents =
  | {
      type: 'updateUser';
      userInfo: UpdateUserLimitedMutationVariables;
      callback?: (res: boolean) => void;
    }
  | {
      type: 'updateUserInfo';
      userInfo: UpdateUserLimitedMutationVariables;
    }
  | {
      type: 'changeOrg';
      org: string;
    }
  | {
      type: 'changeApp';
      app: ProductName;
    }
  | {
      type: 'featureFlags.initialized';
      flags: FeatureFlagType;
    }
  | { type: 'featureFlags.initializedWithDefaultValues' }
  | {
      type: 'featureFlags.update';
      updatedFlag: AvailableFeatureFlags;
      updatedValue: LDFlagValue;
    }
  | {
      type: 'logout';
      callback: () => void;
    }
  | {
      type: 'readyForCommonProjects';
    }
  | {
      type: 'readyForCommonProjects2';
    }
  | {
      type: 'resetHasAvailableTools';
    }
  | {
      type: 'loadUserProjects';
    }
  | {
      type: 'setManagerToolClick';
      func: (route: string) => void;
    }
  | { type: 'loadUser' }
  | { type: 'setupProjectSubscription' }
  | {
      type: 'processProjectsSubscriptionUpdate';
      data: {
        projects: ProjectsSubscriptionLimitedSubscription['tenancy_users_by_pk'];
      };
    }
  | { type: 'loadProjects' };

export const processUserProjects = ({
  projects,
  allProducts,
  accountId,
}: {
  projects: GetUserProjectsLimitedQuery['tenancy_users_by_pk'];
  allProducts: GetUserProjectsLimitedQuery['tenancy_products'];
  accountId: string;
}): CommonProject[] => {
  const userDirectProjects = projects?.user_accounts[0].user_account_projects
    .filter((project) => project.project.accountId === accountId)
    .map((pro) => ({
      project: pro.project,
      roles: pro._user_account_project_roles.map(({ role }) => role),
    }));

  const userTeamInheritedProjects = projects?._user_teams.reduce(
    (allInheritedProjects, currentProject) => {
      currentProject.team.team_accounts.forEach((teamAccount) => {
        teamAccount.team_account_projects.forEach((teamAccountProject) => {
          if (teamAccountProject.project.accountId === accountId) {
            const userDirectProjectIndex = (userDirectProjects || []).findIndex(
              (project) => project.project.id === teamAccountProject.project.id
            );
            if (userDirectProjects && userDirectProjectIndex >= 0) {
              teamAccountProject._team_account_project_roles.forEach(
                ({ role }) => {
                  if (
                    !userDirectProjects[userDirectProjectIndex].roles.find(
                      ({ productId }) => productId === role.productId
                    )
                  ) {
                    userDirectProjects[userDirectProjectIndex].roles.push(role);
                  }
                }
              );
            } else {
              allInheritedProjects.push({
                project: teamAccountProject.project,
                roles: teamAccountProject._team_account_project_roles.map(
                  ({ role }) => role
                ),
              });
            }
          }
        });
      });
      return allInheritedProjects;
    },
    [] as {
      project: GetProductsProps['project'];
      roles: GetProductsProps['assignedRoles'];
    }[]
  );

  const allProjects: CommonProject[] = [
    ...(userDirectProjects || []),
    ...(userTeamInheritedProjects || []),
  ].map((project) => ({
    ...project,
    availableProducts: getProducts({
      project: project.project,
      assignedRoles: project.roles,
      allProducts,
    }),
  }));

  return orderBy(
    allProjects,
    (assignedProject) => assignedProject.project.name.toLowerCase(),
    'asc'
  );
};
